<template>
  <div class="container center">
    <div class="main_container center d-col">
      <div class="py-20 text-center weight-300 font-24">
        {{ $t("home.compliant.title") }}
      </div>
      <div class="py-20 d-flex wrap center w100 center justify-center">
        <img src="img/static/EU_GDPR_compliant.png" alt="" />
        <img src="img/static/hipaa-p-500.png" alt="" />
        <img src="img/static/epam-systems-logo-vector.png" alt="" />
        <img src="img/static/kbc.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  background-color: white;
  color: var(--almost-black);
}

img {
  width: auto;
  max-height: 70px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 60px;
}

@media only screen and (max-width: 576px) {
  img {
    padding-left: 20px;
    padding-right: 20px;
  }

  .main_container {
    flex-direction: column;
  }
}
</style>