<template>
  <div class="relative">
    <transition name="slide-down">
      <div
        class="mobile_dropdown"
        v-if="show && !$screen.md"
        v-outside="toggle"
      >
        <a class="mob_bar_item" href="https://cloud.securmeet.com/login">
          {{ $t("homeNav.account") }}</a
        >
        <hr class="w90 hr" />
        <router-link class="mob_bar_item" to="/">{{
          $t("homeNav.plans")
        }}</router-link>
        <hr class="w90 hr" />
        <router-link class="mob_bar_item" to="enterprise">{{
          $t("homeNav.solutions")
        }}</router-link>
        <hr class="w90 hr" />
        <router-link class="mob_bar_item" to="support">{{
          $t("homeNav.support")
        }}</router-link>
        <hr class="w90 hr" />
        <router-link class="mob_bar_item" to="contact">{{
          $t("homeNav.contact")
        }}</router-link>
      </div>
    </transition>
    <div class="nav_bar">
      <div class="cover hide_on_desktop"></div>
      <div class="main_container d-flex space-between align-center">
        <div class="title">SECURMEET</div>

        <div class="d-flex align-center hide_on_desktop">
          <router-link class="nav_bar_item" to="/"
            >{{ $t("homeNav.plans") }}
          </router-link>
          <router-link class="nav_bar_item" to="enterprise">
            {{ $t("homeNav.solutions") }}
          </router-link>
          <router-link class="nav_bar_item" to="support">
           {{ $t("homeNav.support") }}
         </router-link>
          <router-link class="nav_bar_item" to="contact">{{
            $t("homeNav.contact")
          }}</router-link>
          <LanguageSelector />
          <a href="https://cloud.securmeet.com/login" class="button center">
            {{ $t("homeNav.account") }}</a
          >
        </div>
        <div class="d-flex show_on_tablet">
          <LanguageSelector class="show_on_tablet" />
          <button
            @click="toggle"
            ref="button"
            class="d-flex align-center show_on_tablet pointer"
          >
            <s-icon draggable="false" class="noselect">menu</s-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LanguageSelector from "@/components/Atoms/LanguageSelector";
import { mapState } from "vuex";

gsap.registerPlugin(ScrollTrigger);

export default {
  components: {
    LanguageSelector,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
  },
  computed: {
    ...mapState("toolbars", ["homeMenu"]),
  },
  mounted() {
    this.popupItem = this.$refs.button;

    gsap.from(".cover", {
      scrollTrigger: {
        trigger: ".logo",
        start: "top top",
        end: "+=0",
        scrub: 1,
        toggleActions: "play none reverse none",
      },
      x: 0,
      y: -100,
      duration: 0.7,
    });
  },
};
</script>

<style scoped lang="less">
.mobile_dropdown {
  background-color: #ffffff;
  top: 78px;
  width: 100%;
  position: fixed;
  z-index: 2;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mob_bar_item {
  height: 60px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  color: #222;
  font-size: 15px;
  width: 100%;
}

.nav_bar {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: -2px;
}

.hr {
  border-bottom: 1px solid #c0cdd9 !important;
}

.button {
  margin-left: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 0;
  background-color: hsla(0, 0%, 100%, 0.05);
  background-image: none;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.button:hover {
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 100%, 0.3);
  border-radius: 0;
  background-color: hsla(0, 0%, 100%, 0.15);
  background-image: none;
}

.main_container {
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.cover {
  background-color: black;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.nav_bar_item {
  text-decoration: none;
  margin-right: 25px;
  height: 28px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid transparent;
  color: var(--white);
  display: flex;
  align-items: center;
}

.nav_bar_item:hover {
  text-decoration: underline;
}

.title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 5px;
}

.hide_on_desktop {
  display: flex;
}

.show_on_tablet {
  display: none;
}

@media only screen and (max-width: 795px) {
  .nav_bar {
    background-color: #224765;
  }

  .hide_on_desktop {
    display: none;
  }

  .show_on_tablet {
    display: flex;
  }
}
</style>
