<template>
  <div class="container center d-col trigger">
    <div class="font-36 weight-300 pb-30 pt-40 text-center main_container">
      {{ $t("home.infoBanner.title") }}
    </div>
    <div class="main_container items_container">
      <div
        class="info_item"
        v-for="(item, index) in items"
        :key="index"
        :class="'item-' + index"
      >
        <div class="w100 d-flex justify-end highlight">
          {{ item.tag }}
          <div class="highlighter"></div>
        </div>
        <img class="info_logo" :src="item.img" alt="" />
        <div class="info_title">{{ item.title }}</div>
        <div class="info_body">
          {{ item.body }}
        </div>
        <router-link class="green--text underline" to='/whitepaper'>
         {{ $t("home.infoBanner.learnMore") }}
        </router-link>
      
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  data() {
    return {
    };
  },
  computed: {
    items(){
      return [
        {
          tag: this.$t("home.infoBanner.tag1"),
          img: "img/static/icon-secure-light.svg",
          title: this.$t("home.infoBanner.title1"),
          body: this.$t("home.infoBanner.body1"),
        },
        {
          tag: this.$t("home.infoBanner.tag2"),
          img: "img/static/icon-peer-light.svg",
          title: this.$t("home.infoBanner.title2"),
          body: this.$t("home.infoBanner.body2"),
        },
        {
          tag: this.$t("home.infoBanner.tag3"),
          img:  "img/static/icon-enterprise-light.svg",
          title: this.$t("home.infoBanner.title3"),
          body: this.$t("home.infoBanner.body3"),
        },
      ]
    }
  },
  mounted() {
    gsap.from(".item-0", {
      scrollTrigger: ".info_item",
      x: 0,
      y: 50,
      opacity: 0,
      duration: 0.75,
      delay: 0.2,
      ease: "power1.out",
    });
    gsap.from(".item-1", {
      scrollTrigger: ".info_item",
      x: 0,
      y: 50,
      opacity: 0,
      duration: 0.75,
      delay: 0.3,
      ease: "power1.out",
    });
    gsap.from(".item-2", {
      scrollTrigger: ".info_item",
      x: 0,
      y: 50,
      opacity: 0,
      duration: 0.75,
      delay: 0.4,
      ease: "power1.out",
    });
  },
};
</script>

<style scoped lang="less">
* {
  transition: all ease 0.2s;
}

.container {
  background-color: var(--white);
  color: var(--almost-black);
}

.highlight {
  line-height: 40px;
}

.highlighter {
  margin-left: 20px;
  border-right: 2px solid var(--green);
  line-height: 40px;
}

.main_container {
  display: flex;
  justify-content: space-around;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.items_container {
  padding-bottom: 72px;
  padding-top: 40px;
}

.info_logo {
  max-width: 50px;
}

.info_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  border: 1px solid #ddd;
  padding-top: 20px;
  padding-bottom: 30px;
  visibility: 0;
  flex-basis: 0;
}

.info_title {
  font-size: 18px;
  line-height: normal;
  margin-top: 36px;
  margin-bottom: 10px;
  color: var(--almost-black);
}

.info_body {
  font-size: 13px;
  font-weight: 300;
  line-height: normal;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  color: #616161;
}

@media only screen and (max-width: 795px) {
  .items_container {
    flex-direction: column;
  }

  .info_item {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
