<template>
  <div class="center relative">
    <div class="parallax"></div>
    <div class="text main_container">
      <div class="title">{{ $t("footer.title") }}</div>
      <div class="font-14 show_on_mobile">
        {{ $t("footer.subtitle") }}
      </div>
      <div class="d-flex">
        <div class="body hide_on_mobile links">
          {{ $t("footer.subtitle") }}
        </div>
        <div class="links">
          <div class="d-flex d-col mt-10">
            <router-link to="/support" class="mt-10">{{
              $t("footer.support")
            }}</router-link>
            <router-link to="/contact" class="mt-10">{{
              $t("footer.contact")
            }}</router-link>
            <a href="/privacy-policy" class="mt-10" target="_blank">{{
              $t("footer.privacy-policy")
            }}</a>
             <a href="/data-privacy-addendum" class="mt-10" target="_blank">{{
              $t("footer.data_privacy")
            }}</a>
            <a
              href="/end-user-license-agreement"
              class="mt-10"
              target="_blank"
              >{{ $t("footer.eula") }}</a
            >
            <a href="/terms-of-service" class="mt-10" target="_blank">{{
              $t("footer.tos")
            }}</a>
            <router-link to="/faq" class="mt-10">FAQ</router-link>
            <a href="https://cloud.securmeet.com/login" class="mt-10">{{
              $t("footer.singIn")
            }}</a>
          </div>
        </div>
        <div class="links hide_on_mobile news">
          <div class="title mb-20">{{ $t("footer.news") }}</div>
          <hr class="mb-20" />
          <div class="d-flex mb-20">
            <img class="news_image" src="/img/static/breather.png" alt="" />
            <div>
              {{ $t("footer.news1") }}
            </div>
          </div>
          <hr class="mb-20" />
          <div class="d-flex mb-20">
            <img class="news_image" src="/img/static/boris.png" alt="" />
            <div>
              {{ $t("footer.news2") }}
            </div>
          </div>
          <hr />
        </div>
      </div>
      <div>
        <hr />
        <div class="pt-20 font-12">
          {{ $t("footer.footer") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.parallax {
  background-image: url("/img/static/footer-lock.jpg");
  height: 100%;
  filter: brightness(70%) blur(5px);
  min-height: 400px;
  width: 100%;
  position: absolute;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  top: 0;
}

.text {
  z-index: 1;
  position: absolute;
  padding: 50px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  flex-grow: 2;
  max-width: 1200px;
  top: 0;
}

.title {
  letter-spacing: 5px;
  font-weight: 500;
}

.links {
  font-size: 13px;
  margin-right: 20px;
  flex-grow: 1;
  flex-basis: 0;
}

.news {
  flex-grow: 2;
  margin-right: 0;
}

.news_image {
  max-width: 100px;
  margin-right: 10px;
}

.body {
  font-size: 13px;
  flex-grow: 2;
  max-width: 320px;
  padding-right: 50px;
}

a {
  color: #ffffff;
  text-decoration: underline;
}

@media only screen and (max-width: 576px) {
  .text {
    padding: 10px;
  }
}

@media only screen and (max-width: 795px) {
  .news {
    display: none;
  }
}
</style>
